
import React from 'react';
import styles from './styles.module.scss';

export function Ordinals() {
    const items = [];
    for(let i = 0; i < 100; i++) {
        items.push(
            <div className={styles.frameWrap} key={i}>
                <iframe scrolling="no" src={`/${i + 1}.html`} />
            </div>
        )
    }
    return (
        <div className={styles.wrap}>
        {items}
        </div>
    )
}